<template>
   <div class="follow-us text-center">
      <div class="container">
         <div class="mb-4">
            <h2> Follow Us</h2>
         </div>
         <div class="social-icon-widget">
            <ul class="list-inline mb-0">
               <li class="list-inline-item mx-2">
                  <a href="javascript:void(0)" class="square-60 rounded-circle bg-facebook">
                     <i class="fab fa-facebook-f fa-inverse"></i>
                  </a>
               </li>
               <li class="list-inline-item mx-2">
                  <a href="javascript:void(0)" class="square-60 rounded-circle bg-twitter">
                     <i class="fab fa-twitter fa-inverse"></i>
                  </a>
               </li>
               <li class="list-inline-item mx-2">
                  <a href="javascript:void(0)" class="square-60 rounded-circle bg-google">
                     <i class="fab fa-google-plus-g fa-inverse"></i>
                  </a>
               </li>
               <li class="list-inline-item mx-2">
                  <a href="javascript:void(0)" class="square-60 rounded-circle bg-pinterest">
                     <i class="fab fa-pinterest fa-inverse"></i>
                  </a>
               </li>
               <li class="list-inline-item mx-2">
                  <a href="javascript:void(0)" class="square-60 rounded-circle bg-linked">
                     <i class="fab fa-linkedin-in fa-inverse"></i>
                  </a>
               </li>
            </ul>
         </div>
      </div>
   </div>
</template>

<script>
   export default {
      
   }
</script>
